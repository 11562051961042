import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";
import { DateTime } from "luxon";

import Url from "./url";
import Label from "./label";
import Contributor from "./contributor";
import AffiliateBanner from "./affiliate-banner";

const siteReleasedAt = DateTime.fromISO(process.env.GATSBY_RELEASED_AT!);

export default class Page {
  id: string;
  url: Url;
  breadcrumb: Url[];
  title: string;
  image?: Queries.contentfulPageImageJsonNode;
  description: string;
  releasedAt?: DateTime;
  updatedAt: DateTime;
  labels: Label[];
  content?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  category?: Label;
  metaTitle: string;
  excerpt?: string;
  tableOfContents: boolean;
  contributors: Contributor[];
  faq?: any;
  type?: string;
  sideNavigationAdvertisements: AffiliateBanner[];

  constructor(page: Queries.ContentfulPage) {
    const labels = page.labels?.filter(
      (label) =>
        [
          "exploring-a-better-future",
          "culture-and-lifestyle",
          "workstyle",
          "shaping-a-better-future",
        ].indexOf(label!.slug!) == -1
    );

    const faq = page.content?.references?.find(
      (ref) => ref?.internal?.type == "ContentfulComponentFaq"
    ) as any;

    if (faq) {
      this.faq = {
        title: faq.title,
        items: faq.data,
      };
    }

    this.id = page.id;
    this.url = new Url(page.url!);
    this.breadcrumb = page.breadcrumb?.map((url) => new Url(url!)) || [];

    this.title = page.internalTitle || page.title || "";
    this.metaTitle = page.title || "";
    this.description = page.description?.description || "";
    this.image = page.image as any;

    this.updatedAt = DateTime.fromISO(page.updatedAt!).setZone("Asia/Tokyo");

    if (page.releasedAt) {
      this.releasedAt = DateTime.fromISO(page.releasedAt!).setZone(
        "Asia/Tokyo"
      );

      if (this.releasedAt < siteReleasedAt) {
        this.releasedAt = siteReleasedAt;
      }

      if (this.updatedAt < this.releasedAt) {
        this.updatedAt = this.releasedAt;
      }
    }

    this.tableOfContents = page.tableOfContents || false;

    this.labels = labels?.map((label) => new Label(label!)) || [];

    if (labels && labels.length > 0) {
      this.category = new Label(labels[0]!);
    }

    if (page.content) {
      this.content = page.content as any;
    }

    if (page.excerpt) {
      this.excerpt = page.excerpt;
    }

    if (page.type) {
      this.type = page.type;
    }

    this.contributors =
      page.contributors?.map((contributor) => new Contributor(contributor!)) ||
      [];

    this.sideNavigationAdvertisements =
      page.sideNavigationAdvertisements?.map(
        (banner) => new AffiliateBanner(banner!)
      ) || [];
  }
}
